import React from "react";
import {Link} from "gatsby";
import Layout from "../components/layout"
import {getCurrentLocale, getTranslatedValue} from "../helpers/fields";
const slugs = require("../helpers/slugs");


class Floor extends React.Component {

    getRoomByNameNumber(rooms, number) {
        return rooms.filter((room) => {
            return room.node.fieldValues.name.toLowerCase() === 'zaal ' + number;
        })[0];
    }

    getLinkToRoom(rooms, number) {
        return slugs.getRoomSlug(this.getRoomByNameNumber(rooms, number).node, getCurrentLocale(this));
    }

    render() {
        const locale = getCurrentLocale(this);
        const translations = this.props.pageContext.translations;
        const rooms = this.props.pageContext.rooms.sort((a, b) => {
            const a_number = parseInt(a.node.fieldValues.name.match(/\d+/g)[0]);
            const b_number = parseInt(b.node.fieldValues.name.match(/\d+/g)[0]);
            return a_number > b_number ? 1 : -1;
        });
        const roomPositions = this.props.pageContext.roomPositions;
        const title = this.props.pageContext.title;
        const pageTitle = getTranslatedValue(this, translations.collection) + " " + title;

        return (
            <Layout page={this} title={pageTitle} className='kaart' lang={locale} includeMenu={false} translations={translations}>
                <div className="room-map">
                    <img src="/img/kaarten/mapLevel-1.png"/>

                    {roomPositions.map((number, index) => {
                        return (
                            <Link to={this.getLinkToRoom(rooms, number)} className={"number room-" + index}>{number}</Link>
                        );
                    })}
                </div>

                <div className="room-overview">
                    {rooms.map((room, index) => {
                        return (
                        <p key={index}>
                            <Link to={slugs.getRoomSlug(room.node, locale)}>
                                <span>{room.node.fieldValues.name}</span>
                                {getTranslatedValue(this, room.node.fieldValues.title)}
                            </Link>
                        </p>
                    )})}
                </div>
            </Layout>
        );
    }
}

export default Floor;